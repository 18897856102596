import {React,useState} from 'react'
import parse from 'html-react-parser'
import ServiceImage from '../assets/images/branding.svg'
import ServiceImage2 from '../assets/images/ui-ux.svg'
import ServiceImage3 from '../assets/images/mobile-app.svg'
import ServiceImage4 from '../assets/images/development.svg'
import ServiceImage5 from '../assets/images/marketing.svg'
import ServiceImage6 from '../assets/images/website.svg'
import BannerCards from './BannerCards';
import BannerTabs from './BannerTabs'
import  WebsiteIcon from '../assets/images/website.svg'
import  WebsiteIconTwo from '../assets/images/development.svg'
import  WebsiteIconThree from '../assets/images/ui-ux.svg'
import  WebsiteIconFour from '../assets/images/mobile-app.svg'
import  WebsiteIconFive from '../assets/images/marketing.svg'
import  WebsiteIconSix from '../assets/images/branding.svg'
import imageOne from '../assets/images/solution-1.webp'
import imageTwo from '../assets/images/solution-2.webp'
import imageThree from '../assets/images/solution-3.webp'
import imageFour from '../assets/images/solution-1.webp'
import imageFive from '../assets/images/solution-2.webp'
import imageSix from '../assets/images/solution-3.webp'
import bannerIconOne from '../assets/images/website.svg'
import bannerIconTwo from '../assets/images/development.svg'
import bannerIconThree from '../assets/images/ui-ux.svg'
import bannerIconFour from '../assets/images/mobile-app.svg'
import bannerIconFive from '../assets/images/marketing.svg'
import bannerIconSix from '../assets/images/branding.svg'

function HomeHero() {
    const bannerdata = [
        {ServiceImage: ServiceImage6, bannerTitle:'Website', GetStarted: 'Know More', CardLink:'/web-development',
        bannerHeading: 'Website <span className="brand-color"> Solution </span>', para: 'Transforming visions into vibrant websites where innovation meets digital expression.', bannerIcon: bannerIconOne},

        {ServiceImage: ServiceImage4, bannerTitle:'Development', GetStarted: 'Know More', CardLink:'/software-development',
        bannerHeading: 'Software <span className="brand-color"> Development</span>', para: 'Empowering businesses through tailored solutions and custom software development.', bannerIcon: bannerIconTwo},

        {ServiceImage: ServiceImage3, bannerTitle:'Mobile Apps', GetStarted: 'Know More', CardLink:'/mob-development',
        bannerHeading: 'Mobile <span className="brand-color"> Applications </span>', para: 'Empowering mobility through building next-gen mobile experiences for on-the-go interaction.', bannerIcon: bannerIconFour},

        {ServiceImage: ServiceImage2, bannerTitle:'UI/UX Design', GetStarted: 'Know More', CardLink:'/uiux-design',
        bannerHeading: 'User Interface <span className="brand-color"> Design </span>', para: 'We explore intuitive design for flawless interfaces and user-centric functionality.', bannerIcon: bannerIconThree},

        {ServiceImage: ServiceImage5, bannerTitle:'Marketing', GetStarted: 'Know More', CardLink:'/digital-marketing',
        bannerHeading: 'Digital <span className="brand-color"> Marketing </span>', para: 'Driving growth through targeted digital marketing solutions with strategic impact and amplification.', bannerIcon: bannerIconFive},

        {ServiceImage: ServiceImage, bannerTitle:'Branding', GetStarted: 'Know More', CardLink:'/brand-communication',
        bannerHeading: 'Brand <span className="brand-color"> Experiences </span>', para: 'Crafting distinct brand identities with lasting impressions and memorable brand experiences.', bannerIcon: bannerIconSix}
    ]
    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", ServiceIcon: WebsiteIcon, CardParagraph:"Website", imageUrl:imageOne, LearnMore: 'know More',
            tabContent:[
                {
                    bannerHeading: parse('Website <span className="brand-color"> Development </span>'), para: 'Transforming visions into vibrant websites where innovation meets digital expression.', bannerIcon: bannerIconOne, tabPageLink: 'Know More', innerPageLink: '/web-development'
                }
            ]
        },
        {
            coverId:1, villaNameTabImage:"", ServiceIcon: WebsiteIconTwo, CardParagraph:"Development", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper', LearnMore: 'know more',
            tabContent:[
                {
                    bannerHeading: parse('Software <span className="brand-color"> Development</span>'), para: 'Empowering businesses through tailored solutions and custom software development.', bannerIcon: bannerIconTwo, tabPageLink: 'Know More', innerPageLink: '/oftware-development'
                }
            ]
        },
        {
            coverId:2, villaNameTabImage:"", ServiceIcon: WebsiteIconFour, CardParagraph:"Mobile App", imageUrl:imageFour, ImageShadow:'atmosphere-image-wrapper', LearnMore: 'know more',
            tabContent:[
                {
                    bannerHeading: parse('Mobile <span className="brand-color"> Applications </span>'), para: 'Empowering mobility through building next-gen mobile experiences for on-the-go interaction.', bannerIcon: bannerIconFour, tabPageLink: 'Know More', innerPageLink: '/mob-development'
                }
            ]
        },
        {
            coverId:3, villaNameTabImage:"", ServiceIcon: WebsiteIconThree, CardParagraph:"UI/UX Design", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper',LearnMore: 'know more',
            tabContent:[
                {
                    bannerHeading: parse('User Interface <span className="brand-color"> Design </span>'), para: 'We explore intuitive design for flawless interfaces and user-centric functionality', bannerIcon: bannerIconThree, tabPageLink: 'Know More', innerPageLink: '/uiux-design'
                }
            ]
        },
        {
            coverId:4, villaNameTabImage:"", ServiceIcon: WebsiteIconFive, CardParagraph:"Marketing", imageUrl:imageFive, ImageShadow:'atmosphere-image-wrapper', LearnMore: 'know more',
            tabContent:[
                {
                    bannerHeading: parse('Digital <span className="brand-color"> Marketing </span>'), para: 'Driving growth through targeted digital marketing solutions with strategic impact and amplification.', bannerIcon: bannerIconFive, tabPageLink: 'Know More', innerPageLink: '/digital-marketing'
                }
            ]
        },
        {
            coverId:5, villaNameTabImage:"", ServiceIcon: WebsiteIconSix, CardParagraph:"Branding", imageUrl:imageSix, ImageShadow:'atmosphere-image-wrapper', LearnMore: 'know more',
            tabContent:[
                {
                    bannerHeading: parse('Brand <span className="brand-color"> Experiences </span>'), para: 'Crafting distinct brand identities with lasting impressions and memorable brand experiences.', bannerIcon: bannerIconSix, tabPageLink: 'Know More', innerPageLink: '/brand-commmunication'
                }
            ]
        }
    ]
   console.log("tab  ",tabArray)
   const bannerHeading = 'Welcome to <span className="brand-color">Digitech</span>'
   const para = 'Where innovative solutions fuel empowerment and success flourishes in every venture!'
   const [hoveredHeading, setHoveredHeading] = useState(bannerHeading);
   const [hoveredPara,setHoveredPara]=useState(para);
   const handleHover = (newHeading,newPara) => {
    setHoveredHeading(newHeading);
    setHoveredPara(newPara);
  };
    return (
        <>
            <section>
                <div className='digitech-hero-banner header-space'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='digitech-banner-wrapper digitech-desktop-banner'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-9 col-md-12 col-sm-12'>
                                    <div className='banner-heading text-center'>
                                    <h1>{hoveredHeading && parse(hoveredHeading.toString())}</h1>
                                        <p className='banner-paragrapgh'>{hoveredPara && parse(hoveredPara.toString())}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='digitech-cards-wrapper digitech-desktop-banner'>
                                <div className='row justify-content-center'>
                                    <BannerCards bannerdata={bannerdata} handleHover={handleHover} bannerHeading={bannerHeading} para={para}/> 
                                </div>
                                
                            </div>
                        </div>
                            <div className='hero-responsive-banner'>
                                <BannerTabs tabArray={tabArray}/>
                            </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}
export default HomeHero